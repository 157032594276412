.get-the-app__input
  position: relative

.get-the-app__error
  color: $text-error

.get-the-app__note
  $font-size-small()
  text-align: center

.get-the-app__storebuttons
  display: inline-block

.get-the-app__qr
  width: 130px
  height: @width
  margin: auto
  background: url('https://a.bmstatic.com/iu/250/63/QR-a1-b36afc47f05b72d369d4480dd7586ae6.jpeg') no-repeat center / contain
